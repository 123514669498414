<template>
  <div style="background-color: #fafafa">
    <Myhead :nav="1" :nav3="5"></Myhead>
    <div class="header">
      <div class="header-text">
        <div class="header-title">{{ Language.Ecommerce.E_commerce_services }}</div>
        <div class="btn-contactUs" @click="tocontactUs">{{ Language.Ecommerce.Contact_Us }}</div>
      </div>
    </div>
    <div class="router">
      <div class="routerbox">
        <router-link to="/" class="routerLink">{{ Language.Ecommerce.home }}</router-link> >
        <router-link to="/our-services" class="routerLink">{{ Language.Ecommerce.Our_services }}</router-link> >
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer">&nbsp;{{ Language.Ecommerce.E_commerce_services }}</span>
      </div>
    </div>
    <div class="container">
      <div class="container-introduce">
        {{ Language.Ecommerce.msg1 }}
      </div>
      <div class="abstract">

      </div>

      <div class="abstract">

        <div style="width: 640px; margin-right: 20px;">
          <ul>
            <li><div class="abstract-title">{{ Language.Ecommerce.Enterprise_entry_platform_services }}：</div></li>
            <li>{{ Language.Ecommerce.msg19 }}</li>
          </ul>
        </div>
        <div class="abstract-img">
          <img src="../assets/img/Ecommerce/6.jpg" alt="电商服务"/>
        </div>
      </div>

      <div class="typebox">
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/7.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Product_compliance_consulting }}</div>
              {{ Language.Ecommerce.msg20 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/8.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Product_Internal_Control }}</div>
              {{ Language.Ecommerce.msg21 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/9.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.report }}</div>
              {{ Language.Ecommerce.msg22 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/10.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Supplier_Evaluation }}</div>
              {{ Language.Ecommerce.msg23 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/11.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Platform_appeal }}</div>
              {{ Language.Ecommerce.msg24 }}
            </div>
          </div>
        </div>
      </div>


      <div class="abstract">

        <div style="width: 640px; margin-right: 20px;">
          <ul>
            <li><div class="abstract-title">{{ Language.Ecommerce.E_commerce_platform_services }}：</div></li>
            <li>{{ Language.Ecommerce.msg2 }}</li>
          </ul>
        </div>
        <div class="abstract-img">
          <img src="../assets/img/Ecommerce/12.jpg" alt="电商服务"/>
        </div>
      </div>

      <!--检验类型-->
      <div class="typebox">
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/13.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Document_review_for_platform_entry }}</div>
              {{ Language.Ecommerce.msg16 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/14.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Supplier_Evaluation }}</div>
              {{ Language.Ecommerce.msg17 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/15.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Platform_sampling }}</div>
              {{ Language.Ecommerce.msg3 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/3.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Simple_experience }}</div>
              {{ Language.Ecommerce.msg4 }}
            </div>
          </div>
        </div>
        <div class="item">

          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/16.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Purchase_Experience }}</div>
              {{ Language.Ecommerce.msg5 }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/17.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Shenmai_Quality_Inspection }}</div>
              {{ Language.Ecommerce.msg6 }}
            </div>
          </div>
        </div>

        <div class="item">
          <div class="flexbox">
            <div class="img">
              <img src="../assets/img/Ecommerce/18.jpg" alt="电商服务"/>
            </div>
            <div class="content">
              <div class="title">{{ Language.Ecommerce.Resident_quality_control }}</div>
              {{ Language.Ecommerce.msg18 }}
            </div>
          </div>
        </div>
      </div>

      <div class="abstract bottombox">
        <div>
          <div class="abstract-title">{{ Language.Ecommerce.Testing_standards }}：</div>
          <ul>
            <li>{{ Language.Ecommerce.msg7 }}</li>
            <li>{{ Language.Ecommerce.msg8 }}</li>
            <li>{{ Language.Ecommerce.msg9 }}</li>
            <li>{{ Language.Ecommerce.msg10 }}</li>
          </ul>
        </div>
      </div>


      <div class="abstract bottombox">
        <div>
          <div class="abstract-title">{{ Language.Ecommerce.Testing_items }}：</div>
          <ul>
            <li>{{ Language.Ecommerce.msg11 }}</li>
            <li>{{ Language.Ecommerce.msg12 }}</li>
            <li>{{ Language.Ecommerce.msg13 }}</li>
            <li>{{ Language.Ecommerce.msg14 }}</li>
          </ul>
        </div>
      </div>

      <div class="container-introduce">
        {{ Language.Ecommerce.msg15 }}
      </div>

    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    Myhead,
    Myfoot
  },
  methods:{
    tocontactUs(){
      this.$router.push("/contact")
    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-top: -70.5px;
  width: 100%;
  height: 360px;
  color: #FFFFFF;
  background-image: url("../assets/img/certificateService/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header-text {
    max-width: 1200px;
    margin: auto;
    padding-top: 150px;

    .header-title {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
}

.router {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;
  .routerbox{
    width: 1200px;
    margin: auto;
  }
}

.container {
  width: 1200px;
  margin: auto;
  //padding: 0 360px;
  color: var(--title-color);

  .container-introduce {
    margin: 80px 0;
    letter-spacing: 1px;
    line-height: 28px;
  }

  .abstract {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .abstract-title {
      font-size: 42px;
      color: #000000;
    }

    ul {
      padding-inline-start: 20px;
      color: var(--text-color);

      li {
        margin-bottom: 8px;
      }
    }

    .abstract-img {
      width: 460px;
      height: 260px;

      img {
        width: 100%;
        height: 100%;
        //object-fit: cover;
        //border-radius: 5px;
      }
    }
  }
}

.typebox {
  .item {
    padding: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 30px;

    .title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .flexbox {
      display: flex;
    }

    .img {
      width: 460px;
      height: 260px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .content {
      width: calc(100% - 460px - 20px);
      margin-left: 20px;
      font-size: 16px;
    }
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .header{
    .header-text{
      padding: 0px 20px;
      padding-top: 150px;
      .header-title{
        font-size:40px;
      }
      .btn-contactUs{
        width: 130px;
        line-height: 35px;
        height: 35px;
      }
    }
  }
  .router{
    .routerbox{
      padding: 0px 20px;
    }
  }
  .container{
    width: 100%;
    .container-introduce{
      padding: 0px 20px;
      width: 100%;
    }
    .abstract{
      padding: 0px 20px;
    }
  }
  .typebox{
    padding: 0px 20px;
  }
}

@media (min-width: 0px) and (max-width: 800px){
  .show{
    display: block;
  }
  .hide{
    display: none;
  }
  .container{
    .abstract{
      flex-wrap: wrap;
      padding: 0px;
      .textbox{
        width: 100%;
        margin: 0px;
        padding: 0px 20px;
      }
      .abstract-img{
        width: 100%;
        height: auto;
      }
    }
  }
  .typebox{
    padding:  0px;
    .item{
      padding: 20px 0px;
      .flexbox{
        flex-wrap: wrap;
        .img{
          width: 100%;
          height: auto;
        }
        .content{
          width: 100%;
          margin: 0;
          padding: 20px;
        }
      }
    }
  }
  .bottombox{
    padding: 0px 20px !important;
  }
}
@media (min-width: 0px) and (max-width: 1920px) {
  .header{
    background-size: auto 100%;
  }
}
</style>
